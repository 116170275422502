<template>
  <ais-menu
    :attribute="attribute"
    :limit="200"
    :sort-by="sortBy"
  >
    <ul slot-scope="{ items, refine }" class="menu-list">
      <li
        v-for="(facet, index) in items"
        :key="facet.name"
        :class="`${(index - 1) % 2 == 0 ? 'has-background-grey-lighter' : ''}`"
      >
        <label class="is-block">
          <input
            v-model="facet.isRefined"
            type="checkbox"
            :value="facet.name"
            class="is-hidden"
            @change="refine(facet.value)"
          >
          <a :class="{ 'is-active': facet.isRefined }">
            <span class="facet-name">{{ facet.label }}</span>
            <span class="tag is-pulled-right">{{ facet.count.toLocaleString() }}</span>
          </a>
        </label>
      </li>
    </ul>
  </ais-menu>
</template>

<script>
export default {
  props: {
    attribute: {
      type: String,
      required: true,
    },
    sortBy: {
      type: Array,
      default() {
        return ['sponsor:desc', 'isRefined:desc', 'name:asc', 'count:desc'];
      },
    },
  },
};
</script>
