<template>
  <ais-hierarchical-menu
    :attributes="[attribute + '.lvl0', attribute + '.lvl1']"
    :limit="200"
    :class-names="{
      'ais-HierarchicalMenu': 'menu-list',
      'ais-HierarchicalMenu-count': 'tag level-item is-justify-content-flex-end',
      'ais-HierarchicalMenu-item--active': 'has-text-weight-bold',
      'ais-HierarchicalMenu-link': 'level is-mobile',
      'ais-HierarchicalMenu-label': 'level-item is-justify-content-flex-start is-flex-shrink-1',
    }"
  />
</template>

<script>
export default {
  props: {
    attribute: {
      type: String,
      required: true,
    },
  },
};
</script>
