<template>
  <nav role="navigation" aria-label="search facet navigation">
    <div class="navbar-brand">
      <div class="navbar-item has-text-grey is-hidden-touch is-flex is-flex-direction-column">
        <span class="mb--1">FILTER</span>
        <span v-if="currentDirectory" class="pl-1 has-text-weight-semibold current-directory">
          {{ currentDirectory.name }}
        </span>
      </div>
      <a v-for="facet in facets" :key="facet.name" class="navbar-item">
        <div class="dropdown is-hoverable">
          <div class="dropdown-trigger">
            <a class="button">
              <span>{{ facet.name }}</span>
              <span class="icon is-small has-text-grey-dark">
                <fa icon="angle-down" />
              </span>
            </a>
          </div>
          <div class="dropdown-menu">
            <div v-if="facet.type === 'array'" class="dropdown-content is-small">
              <FacetFilter :attribute="facet.key" />
            </div>

            <div v-else-if="facet.type === 'string'" class="dropdown-content is-small">
              <StringFacetFilter :attribute="facet.key" />
            </div>

            <div v-else-if="facet.type === 'tree'" class="dropdown-content is-small">
              <TreeFacetFilter :attribute="facet.key" />
            </div>
          </div>
        </div>
      </a>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import FacetFilter from '@/components/InstantSearch/FacetFilter.vue';
import StringFacetFilter from '@/components/InstantSearch/StringFacetFilter.vue';
import TreeFacetFilter from '@/components/InstantSearch/TreeFacetFilter.vue';

export default {
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
      facets: 'fields/facets',
    }),
  },
  components: {
    FacetFilter,
    StringFacetFilter,
    TreeFacetFilter,
  },
};
</script>
