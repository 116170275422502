<template>
  <nav id="navbar" class="navbar is-fixed-top">
    <BackLink />
    <div class="header columns is-vcentered is-marginless">
      <router-link v-if="logoUri" to="/" class="navbar-logo column is-narrow" aria-label="Home">
        <img class="logo" :src="logoUri" alt="Directory Hero">
      </router-link>
      <Ad ad-slot="LeaderboardAd" />
    </div>
    <div
      id="facet-nav"
      class="columns is-vcentered is-marginless is-mobile"
      :class="{ 'is-hidden': routeRecord }"
    >
      <FacetNav class="column is-narrow is-paddingless is-hidden-touch" />
      <div class="column">
        <div class="field is-flex is-mobile">
          <PrimaryFacetFilter v-if="primaryFilter" class="control is-hidden-desktop" />
          <p class="control has-icons-left is-fullwidth">
            <DebouncedSearchBox
              :placeholder="`Search ${currentDirectory.name} by Name, ${facetNames}`"
            />
          </p>
        </div>
      </div>
      <div class="column is-narrow has-text-right is-hidden-touch">
        <ais-state-results>
          <template slot-scope="{ results: { nbHits } }">
            <span>{{ nbHits.toLocaleString() }} Results</span>
          </template>
        </ais-state-results>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import FacetNav from '@/components/SearchResults/FacetNav.vue';
import PrimaryFacetFilter from '@/components/InstantSearch/PrimaryFacetFilter.vue';
import DebouncedSearchBox from '@/components/InstantSearch/DebouncedSearchBox.vue';
import BackLink from './BackLink.vue';

export default {
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
      primaryFilter: 'directory/primaryFilterField',
      directoryCount: 'directory/count',
      facets: 'fields/facets',
    }),
    ...mapState({
      logoUri: (state) => state.logo_uri,
      directories: (state) => state.directories,
      routeRecord: (state) => state.route.params.record,
    }),
    facetNames() {
      return this.facets.map((facet) => facet.name).join(', ');
    },
  },
  methods: {
    navigateDirectory(e, refine) {
      refine();

      this.$router.push({
        name: 'directory',
        query: null,
        params: { directory: e.target.link },
      });
    },
    routerLink(directory) {
      return `/${directory.slug}/`;
    },
  },
  components: {
    FacetNav,
    Ad: () => import('@/components/Ads/Ad.vue'),
    DebouncedSearchBox,
    PrimaryFacetFilter,
    BackLink,
  },
};
</script>
