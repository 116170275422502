<template>
  <ais-search-box
    :class-names="{
      'ais-SearchBox-form': 'form',
      'ais-SearchBox-submit': 'is-hidden',
      'ais-SearchBox-submitIcon': 'is-hidden',
      'ais-SearchBox-reset': 'is-hidden',
      'ais-SearchBox-resetIcon': 'is-hidden',
    }"
    aria-label="Search"
    autofocus
  >
    <input
      v-model="query"
      :placeholder="placeholder"
      class="input searchBox"
      type="search"
    >
    <span class="icon has-text-grey is-left is-large">
      <fa icon="search" size="lg" full-width />
    </span>
  </ais-search-box>
</template>

<script>
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import { createWidgetMixin } from 'vue-instantsearch';

export default {
  mixins: [createWidgetMixin({ connector: connectSearchBox })],
  props: {
    delay: {
      type: Number,
      default: 200,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      timerId: null,
      localQuery: '',
    };
  },
  computed: {
    query: {
      get() {
        return this.localQuery;
      },
      set(val) {
        this.localQuery = val;
        if (this.timerId) {
          clearTimeout(this.timerId);
        }
        this.timerId = setTimeout(() => {
          this.state.refine(this.localQuery);
        }, this.delay);
      },
    },
  },
  destroyed() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  },
};
</script>
